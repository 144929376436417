<template>
    <div>
        <div v-if="authUserPermission['stock-calc-slow-by-demand']">
            <base-header class="pb-6">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h6 class="h2 text-white d-inline-block mb-0"></h6>
                    </div>      
                </div>
            </base-header>
            <div class="container-fluid mt--6">
                <div class="card mb-4">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-7">
                                <h3 class="mb-0">Stock Calculation by History Demand</h3>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-4">
                                <label class="form-control-label"><b>Gunakan Data TA</b>
                                    <el-tooltip content="Pilihan Mengikutsertakan Data Demand Turn Around pada Perhitungan" placement="top">
                                    <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                    </el-tooltip> 
                                </label><br>
                                <el-select v-model="dataTa" style="width:100%" placeholder="Choose" :disabled="loadingCalculate" @change="dependCompany()">
                                    <el-option label="Ya" value="true"></el-option>
                                    <el-option label="Tidak" value="false"></el-option>
                                </el-select>

                                <label class="form-control-label mt-3"><b>Consequences Risk</b>
                                    <el-tooltip placement="top">
                                        <div slot="content">
                                            <p>Potensi Dampak, konsekuensi, dan vulnerability pada aset terhadap ancaman atau bahaya.</p>
                                            <button type="button" class="btn btn-sm btn-primary mt--3" @click="showImage()">Matriks</button>
                                        </div>
                                        <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                    </el-tooltip> 
                                </label><br>
                                <el-select v-model="criticality" style="width:100%" placeholder="Choose" :disabled="loadingCalculate">
                                    <el-option label="Criticality Direct Effect or Potential Lost >2M" value="Criticality Direct Effect or Potential Lost >2M"></el-option>
                                    <el-option label="Criticality Indirect Effect or Potential Lost 1M-2M" value="Criticality Indirect Effect or Potential Lost 1M-2M"></el-option>
                                    <el-option label="Non Operational or Potential Lost <1M" value="Non Operational or Potential Lost <1M"></el-option>
                                </el-select>

                                
                                <label class="form-control-label mt-3"><b>Demand Rate</b>  
                                     <el-tooltip content="Frekuensi suatu komponen mengalami kegagalan/kerusakan dalam periode 5 tahun SAP. Disarankan demand rate dibawah nilai 2" placement="top">
                                        <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                    </el-tooltip> 
                                </label>
                                <input class="form-control" placeholder="Demand Rate" type="number" v-model="demandRate" :disabled="loadingCalculate">

<!-- 
                                <label class="form-control-label mt-3"><b>Jumlah Component</b>
                                     <el-tooltip content="Jumlah Komponen yang terpasang dalam satu equipment" placement="top">
                                        <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                    </el-tooltip> 
                                </label>
                                <input class="form-control" placeholder="Jumlah Component" type="number" v-model="jumlahComponent" :disabled="loadingCalculate"> -->
                            </div>
                            <div class="col-4">
                                <label class="form-control-label"><b>Anak Perusahaan</b>
                                     <el-tooltip content="Anak perusahan dalam PI Grup" placement="top">
                                        <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                    </el-tooltip> 
                                </label><br>
                                <el-select v-model="anakPerusahaan" @change="dependCompany()" style="width:100%;" placeholder="Choose" :disabled="loadingCalculate">
                                    <el-option v-for="company in companies" :key="'cmp'+company.code" :value="company.code" :label="company.code+' - '+company.name"></el-option>
                                </el-select>


                                 <label class="form-control-label mt-3"><b>LeadTime (Bulan)</b>
                                    <el-tooltip content="Durasi waktu semenjak user (perencanaan pemeliharaan) menginformasikan permintaan Spare Part sampai dengan waktu kedatangan nya (PR-GR)" placement="top">
                                        <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                    </el-tooltip> 
                                </label>
                                <input class="form-control" placeholder="Lead Time" type="number" v-model="leadTime" :disabled="loadingCalculate">

                                <label class="form-control-label mt-3"><b>Service Level (%)</b>
                                     <el-tooltip content="Kemampuan memberikan pelayanan kepada user pemeliharaan yang diukur berdasarkan rasio antara tingkat ketersediaan (availability) Material Stock dengan frekuensi permintaan" placement="top">
                                        <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                    </el-tooltip> 
                                </label>
                                <input class="form-control" placeholder="Service Level (%)" type="number" v-model="serviceLevel" :disabled="loadingCalculate">
                            </div>  
                            <div class="col-4">
                                <label class="form-control-label"><b>Material Number</b>
                                    <el-tooltip content="Material Spare Part" placement="top">
                                        <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                    </el-tooltip> 
                                </label><br>
                                <el-select v-model="materialNumber" filterable style="width:100%" placeholder="Material Number" :loading="selectSearch.material" :disabled="loadingCalculate">
                                    <el-option v-for="(mn, k) in materialNumbers" :key="k" :value="mn" :label="mn"></el-option>
                                </el-select>

                                <label class="form-control-label mt-3"><b>Harga Sparepart (IDR)</b>
                                    <el-tooltip content="Harga satuan Spare Part dalam rupiah" placement="top">
                                        <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                    </el-tooltip> 
                                </label>
                                <input class="form-control" placeholder="Harga Sparepart" v-model="hargaSparepart" @keyup="formatPrice($event, 'hargaSparepart')" @change="formatPrice($event, 'hargaSparepart')" :disabled="loadingCalculate">


                            </div>
                        </div>

                        <div class="text-center mt-5">
                            <base-button type="default" @click="download()" :disabled="disableCalculation || disableDownload || loadingDownload || loadingCalculate">
                                <span v-if="loadingDownload"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                                <span v-else>Download</span>
                            </base-button>
                            <input type="button" class="btn btn-danger" value="Run Calculation" @click="calculate()" :disabled="disableCalculation || loadingCalculate">
                             <base-button  type="default" @click="resetParameter"  :disabled="loadingDownload || loadingCalculate">Reset</base-button>
                        </div>

                        <hr>

                        <div v-show="!loadingCalculate">
                            <div class="mt-3">
                                <div class="row">
                                    <div class="col-12">
                                        <div  v-if="calculation != null">
                                            <h2>Hasil</h2>
                                            <div class="alert alert-success" role="alert" style="font-size:14pt">
                                                Jika service level sebesar <b>{{ serviceLevel }}%</b> maka direkomendasikan stok sebanyak <b>{{ calculation["Stock Optimum"]}}</b> sparepart, dengan total biaya <b>{{formatNumber(calculation["Biaya"] )}}</b>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-3"  v-show="calculation != null">
                                <h2>Detail Service Level</h2>
                                <div class="table-responsive">
                                    <table class="table table-bordered table-striped">
                                        <thead class="bg-secondary">
                                            <tr>
                                                <th style="font-size:12pt" class="text-center">Sparepart  
                                                    <el-tooltip content="Jumlah stok Spare Part MRO" placement="top">
                                                        <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                                    </el-tooltip> 
                                                </th>
                                                <th style="font-size:12pt" class="text-center">Service Level
                                                    <el-tooltip content="Kemampuan memberikan pelayanan kepada user pemeliharaan yang diukur berdasarkan nilai cumulative distribution function (CDF) distribusi Poisson" placement="top">
                                                        <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                                    </el-tooltip> 
                                                </th>
                                                <th style="font-size:12pt" class="text-center">Total Biaya
                                                    <el-tooltip content="Total biaya yang dikeluarkan dari total Spare Part yang dipesan" placement="top">
                                                        <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                                    </el-tooltip> 
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(data, key) in tables" :key="'table' + key">
                                                <td style="font-size:12pt">{{ data.sparepart}}</td>
                                                <td style="font-size:12pt">{{ data.service_level}}</td>
                                                <td style="font-size:12pt">{{ data.total_harga}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div class="mt-3" v-show="calculation != null">
                                <div class="row">
                                    <div class="col-12">
                                        <h2 v-if="calculation != null">
                                            Grafik Distribusi 
                                            <el-tooltip content="Grafik distribusi poisson" placement="top">
                                                <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                            </el-tooltip> 
                                        </h2>
                                        <canvas id="myChart"></canvas>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="mt-5" v-show="calculation == null">
                                <div>
                                    <empty></empty>
                                </div>
                            </div>
                        </div>
                        <skeleton-loading v-show="loadingCalculate"></skeleton-loading>
                    </div>
                </div>
            </div>
        </div>
        <noaccess v-else/>
        <modal :show.sync="detailImage" size="lg">
            <template slot="header">
                <h5 class="modal-title">Detail</h5>
            </template>
            <template>
                <img src="/assets/smartcat/stock-calc-image.jpg" alt="" class="img-fluid">
            </template>
        </modal>
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import axios from 'axios'
    import Chart from 'chart.js/dist/chart.js'
    import SkeletonLoading from '../../components/SkeletonLoading.vue';

    export default {  
        components:{SkeletonLoading},      
        data() {
            return {          
                anakPerusahaan: '',
                materialNumber: '',
                jumlahComponent: 1,
                annualCarryingCost: '',
                serviceLevel: 98,
                hargaSparepart: '',
                loadingField:false,
                materialNumber:'',
                anakPerusahaan:'',
                materialNumbers:[],
                loadingField:false,
                calculation:null,
                demandRate:'',
                equipmentCodes:[],
                paramsDownload:{
                    equipmentCodes:[],
                    materialNumbers:[],
                    materialNumber:'',
                    equipment:'',
                    anakPerusahaan:''
                },
                tables:[],
                selectSearch:{
                    materialNumber:false,
                },
                canChart:null,
                loadingDownload:false,
                loadingCalculate:false,
                dataTa:'',
                criticality:'',
                detailImage:false,
            }
        },
        computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
            disableDownload(){
                if(!!!this.materialNumber || !!!this.anakPerusahaan){
                    return true;
                }else{
                    return false;
                }
            },
            disableCalculation(){
                if(!!!this.materialNumber || !!!this.anakPerusahaan || this.demandRate == '' || !!!this.leadTime || !!!this.jumlahComponent || !!!this.serviceLevel || !!!this.hargaSparepart){
                    return true;
                }else{
                    return false;
                }
            },
            companies(){
                let data = [
                    {
                        code:'PKG',
                        name:'PT Petrokimia Gresik',
                        code2:'B601',
                    },{
                        code:'PKC',
                        name:'PT Pupuk Kujang',
                        code2:'C601'
                    },{
                        code:'PKT',
                        name:'PT Pupuk Kalimantan Timur',
                        code2:'D601'
                    },{
                        code:'PIM',
                        name:'PT Pupuk Iskandar Muda',
                        code2:'E601'
                    },{
                        code:'PSP',
                        name:'PT Pupuk Sriwidjaja',
                        code2:'F601'
                    }]
                return data;
            }
        },
        watch:{
            'calculation': function(value){

                if(value != null){
                    this.tables = []
                    if(this.canChart){
                        this.canChart.destroy()
                    }
                    // var sparepartValues =Object.values(this.calculation['Tabel']['Sparepart'])
                    // var cdfValues =Object.values(this.calculation['Tabel']['% CDF'])
                    // var totalbiayasValues =Object.values(this.calculation['Tabel']['Total Harga'])

                    // sparepartValues.forEach((element, index) => {
                    //     var d = {
                    //         sparepart:element,
                    //         service_level:cdfValues[index],
                    //         total_harga:this.formatNumber(totalbiayasValues[index])
                    //     }

                    //     this.tables.push(d)
                    // })

                    var cdfValues =Object.keys(this.calculation['tabel CDF']['% CDF'])

                    cdfValues.forEach((element) => {
                        var d = {
                            sparepart:this.calculation['tabel CDF']['Sparepart'][element],
                            service_level:this.calculation['tabel CDF']['% CDF'][element],
                            total_harga:this.formatNumber(this.calculation['tabel CDF']['Total Harga'][element])
                        }
                        this.tables.push(d)

                    })


                    // this.tables = this.tables.sort((p1, p2) => (p1.service_level < p2.service_level) ? 1 : (p1.service_level > p2.service_level) ? -1 : 0);

                    const ctx = document.getElementById('myChart');

                    let datapoints1   = Object.keys(this.calculation["tabel distribusi"]["CDF"])
                        
                    let structredData1 = [];
                    let structredData2 = [];
                    let labels = [];

                    datapoints1.forEach((element) => {
                        structredData1.push({
                            cdf:this.calculation["tabel distribusi"]["CDF"][element],
                            data:{
                                sl:this.calculation["tabel distribusi"]["SL"][element],
                            }
                        })

                        structredData2.push({
                            sp:this.calculation["tabel distribusi"]["Sparepart"][element],
                            data:{
                                sl:this.calculation["tabel distribusi"]["SL"][element],
                            }
                        })
                    })
                    
                    structredData2 = structredData2.sort((a,b) => {
                                            return a + b
                                    })
                    structredData2.forEach((structure) =>{
                        labels.push(structure.sp)
                    })
                             
                    
                    const data = {
                        labels:labels,
                        datasets: [
                            {
                                label: 'Sparepart',
                                data: structredData1,
                                borderColor: 'rgb(51, 51, 255)',
                                tension: 0.4,
                                parsing:{
                                    yAxisKey:'cdf',
                                    xAxisKey:'data.sl',
                                }
                            },
                            {
                                label: 'Service Level',
                                data: structredData2,
                                borderColor: 'rgb(255, 99, 132)',
                                pointRadius:0.6,
                                parsing:{
                                    xAxisKey:'sp',
                                    yAxisKey:'data.sl',

                                },
                                segment:{
                                    borderDash:[6,6]
                                }
                            }, 
                        ]
                    };

                    this.canChart = new Chart(ctx, {
                        type: 'line',
                        data: data,
                        options: {
                            responsive: true,
                            plugins: {
                                tooltip: {
                                    enabled: true,
                                    callbacks: {
                                        label: function(context) {
                                            if(context.dataset.label == 'Sparepart'){
                                                return context.dataset.label + ' ' + context.label;
                                            }else{
                                                return context.dataset.label + ' ' + context.parsed.y;
                                            }
                                        },
                                        title: function(context){
                                            return ''
                                        }
                                    }

                                }
                            },
                            scales: {
                                x: {
                                    suggestedMin: 0,
                                },
                                y: {
                                    suggestedMin: 0,
                                }
                            }
                        },
                    });
                }else{
                    this.tables = []
                }
            },
            'anakPerusahaan' : function(){
                if(this.anakPerusahaan != '' && this.criticality != '' && this.materialNumber != '' && this.dataTa != ''){
                    this.dataHistory();
                }
            },
            'dataTa': function(){
               if(this.anakPerusahaan != '' && this.criticality != '' && this.materialNumber != '' && this.dataTa != ''){
                    this.dataHistory();
               } 
            },
            'criticality': function(){
               if(this.anakPerusahaan != '' && this.criticality != '' && this.materialNumber != '' && this.dataTa != ''){
                    this.dataHistory();
               } 
            },
            'materialNumber' : function(value){
                if(this.anakPerusahaan != '' && this.criticality != '' && this.materialNumber != '' && this.dataTa != ''){
                    this.dataHistory();
                }
            },
        },
        methods: {
            async calculate() {
                this.loadingCalculate = true;
                let anakPerusahaan = this.companies.filter(element => element.code === this.anakPerusahaan)[0].code2;
                var hargaSparepart = Number(this.hargaSparepart.replaceAll('.','').replaceAll(',','.'))
                let params = JSON.stringify(
                {
                    "ematn_input":Number(this.materialNumber.split(";")[0]),
                    "werks_input":anakPerusahaan,
                    "criticality" : this.criticality,
                    "service_level":Number(this.serviceLevel),
                    "sparepart_input":Number(this.jumlahComponent),
                    "mseg_with_TA": this.dataTa,
                    "harga_sparepart":hargaSparepart,
                    "demand_rate":Number(this.demandRate),
                    "leadtime_input":Number(this.leadTime),
                })
                var config = {
                    method: 'post',
                    url: 'https://api-sm.pupuk-indonesia.com/slow-moving',
                    headers: { 
                        'Access-Control-Allow-Origin' : '*',
                        'Content-Type': 'application/json',
                    },
                    data : params
                };
                this.calculation = null;
                await axios(config).then(response =>  this.calculation =  response.data)
                .catch(error => this.calculateError());
                this.loadingCalculate = false;
            },
            async download() {

                this.loadingDownload = true;
                let params = JSON.stringify(
                {
                    "ematn_input":Number(this.paramsDownload.materialNumber),
                    "werks_input":this.paramsDownload.anakPerusahaan,
                })
                var config = {
                    method: 'post',
                    url: 'https://api-sm.pupuk-indonesia.com/get_csv',
                    headers: { 
                        'Content-Type': 'application/json'
                    },
                    responseType: 'blob',
                    data : params
                };
                await axios(config).then(({ data }) => {

                    const downloadUrl = window.URL.createObjectURL(new Blob([data]));

                    const link = document.createElement('a');

                    link.href = downloadUrl;

                    link.setAttribute('download', 'file.csv'); //any other extension

                    document.body.appendChild(link);

                    link.click();

                    link.remove();

                })
                .catch(error => this.calculateError('download'));

                this.loadingDownload = false;
            },
            async dependCompany(){
                if(this.dataTa == '' || this.anakPerusahaan == ''){
                    this.$notify({
                        message: this.tt("please_complete_data", { title: 'Company or Data TA' }),                  
                        type: 'danger',
                        verticalAlign: "bottom",
                        horizontalAlign: "left"
                    });
                    return;
                }
                var params =  JSON.stringify({
                                "Perusahaan" :this.anakPerusahaan,
                                "Gunakan data TA": this.dataTa
                            })
                var config = {
                    method: 'post',
                    url: 'https://api-sm.pupuk-indonesia.com/material-list',
                    headers: { 
                        'Content-Type': 'application/json'
                    },
                    data : params
                };
                this.materialNumbers = []
                this.materialNumber = ''
                this.leadTime = ''
                this.hargaSparepart = ''
                this.jumlahComponent = 1
                this.demandRate = 98
                this.serviceLevel = ''
                await axios(config).then(response =>  this.materialNumbers =  response.data["Material"]).catch(error => {
                    this.$notify({
                        message: 'Retrieved Data Company Failed',                  
                        type: 'danger',
                        verticalAlign: "bottom",
                        horizontalAlign: "left"
                    });
                })
                .catch(() => this.materialNumbers = []);
            },
            completeDataNotify(title) {
            	this.$notify({
					message: this.tt("please_complete_data", { title: title }),                  
					type: 'danger',
					verticalAlign: "bottom",
					horizontalAlign: "left"
				});
            },
            formatNumber(value) {
                let convert = value.toString();
                let splitValue  = convert.split('.');
                let withDot = splitValue[0].replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                if(!!splitValue[1]){
                    return 'Rp. ' + withDot + ',' + splitValue[1]
                }else{
                    return 'Rp. ' + withDot
                }
            },
            resetParameter(){
                    this.materialNumber = ''
                    this.anakPerusahaan = ''
                    this.serviceLevel = 98
                    this.leadTime = ''
                    this.jumlahComponent = 1
                    this.hargaSparepart = ''
                    this.demandRate = ''
                    this.materialNumbers = [];
                    this.calculation = null;
                    this.dataTa = ''
                    this.criticality = ''
            },
            formatPrice(val, variabel) {
                var price = val.target.value.toString().replace(/[^,\d]/g, "")
                price = price.replace(/[^,\d]/g, "").toString();
                var split = price.split(",");
                var lastPrice = split[0].length % 3;
                var formatRupiah = split[0].substr(0, lastPrice);
                var ribuan = split[0].substr(lastPrice).match(/\d{3}/gi);

                if (ribuan) {
                    var separator = lastPrice ? "." : "";
                    formatRupiah += separator + ribuan.join(".");
                }
                formatRupiah = split[1] != undefined ? formatRupiah + "," + split[1] : formatRupiah;
                this[variabel] = formatRupiah
            },
            calculateError(type = ''){
                this.$notify({
					message: type == '' ? 'Calculate Data is Error' : 'Download Data is Error',                   
					type: 'danger',
					verticalAlign: "bottom",
					horizontalAlign: "left"
				});
            },
            async dataHistory(){
                let anakPerusahaan = this.companies.filter(element => element.code === this.anakPerusahaan)[0].code2;
                let params = JSON.stringify(
                {
                    "ematn_input":Number(this.materialNumber.split(";")[0]),
                    "werks_input":anakPerusahaan,
                    "criticality":this.criticality,
                    "mseg_with_TA":this.dataTa
                })

                var config = {
                    method: 'post',
                    url: 'https://api-sm.pupuk-indonesia.com/data_history',
                    headers: { 
                        'Content-Type': 'application/json'
                    },
                    data : params
                };

                await axios(config).then(response => this.processHistory(response.data)).catch(error => this.processHistoryError())
            },
            processHistory(data){
                this.leadTime = data["leadtime"]
                this.serviceLevel = data["Service Level"]
                this.demandRate = data["Demand Rate"]
                this.formatPrice({
                    target:{
                        value:data["Harga Sparepart"]
                    }
                }, "hargaSparepart")
            },
            processHistoryError(){
                this.leadTime = 0
                this.serviceLevel = 0
                this.hargaSparepart = 0
                this.demandRate = 0;
                this.$notify({
                    message: 'Check Data History is Failed',                  
                    type: 'danger',
                    verticalAlign: "bottom",
                    horizontalAlign: "left"
                });
            },
            showImage(){
                this.detailImage = !this.detailImage
            }

        }   
    };
</script>

<style>
.el-tooltip__popper {
  max-width: 320px !important;
  word-break: break-word;
}
</style>